import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { LogoSvg, InfoIcon } from "@/assets/svg";
import { Nav, ResponsiveRender } from "@/components";
import { useQueryParam } from "@/hooks";
import { getSession, authSelector, logout } from "@/redux/slices/authSlice";
import { getStorage, setStorage } from "@/utils";
import { constants, storageKeys } from "@/constants";
import {
  getUnreadMessages,
  messagesSelector,
} from "@/redux/slices/messagesSlice";
import {
  globalSelector,
  setGlobalThemeAsync,
} from "@/redux/slices/globalSlice";

import * as S from "./Header.styles";
import { Switch } from "../common";

const ExternalLinksFirst = [
  {
    as: "a",
    href: "https://cashbox.bg/%D0%BA%D0%B0%D0%BA-%D0%B4%D0%B0-%D0%B2%D0%B7%D0%B5%D0%BC%D0%B0-%D0%BA%D1%80%D0%B5%D0%B4%D0%B8%D1%82/",
    target: "_blank",
    label: "как да взема кредит",
  },
  {
    as: "a",
    href: "https://cashbox.bg/%d0%ba%d0%b0%d0%ba-%d0%b4%d0%b0-%d0%b2%d1%8a%d1%80%d0%bd%d0%b0-%d0%ba%d1%80%d0%b5%d0%b4%d0%b8%d1%82/",
    target: "_blank",
    label: "как да върна кредит",
  },
  {
    as: "a",
    href: "https://cashbox.bg/%d0%b2%d1%8a%d0%bf%d1%80%d0%be%d1%81%d0%b8/",
    target: "_blank",
    label: "ВЪПРОСИ",
  },
];

const ExternalLinksSecond = [
  {
    as: "a",
    href: "https://cashbox.bg/%d0%b1%d1%8a%d1%80%d0%b7%d0%b8-%d0%ba%d1%80%d0%b5%d0%b4%d0%b8%d0%b8/",
    target: "_blank",
    label: "БЪРЗИ КРЕДИТИ",
  },
  {
    as: "a",
    href: "https://cashbox.bg/%d0%b8%d0%bf%d0%be%d1%82%d0%b5%d1%87%d0%bd%d0%b8-%d0%ba%d1%80%d0%b5%d0%b4%d0%b8%d1%82%d0%b8/",
    target: "_blank",
    label: "ИПОТЕЧНИ КРЕДИТИ",
  },
  {
    as: "a",
    href: "https://cashbox.bg/%d1%8e%d1%80%d0%b8%d0%b4%d0%b8%d1%87%d0%b5%d1%81%d0%ba%d0%be-%d0%bb%d0%b8%d1%86%d0%b5/",
    target: "_blank",
    label: "БИЗНЕС КРЕДИТИ",
  },
];

const LogoComponent = () => (
    <S.Logo to="/">
      <LogoSvg />
    </S.Logo>
);

const HeaderComponent = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = !!getStorage(storageKeys.token);

  const hideMainNavDesktop =
      pathname === "/login" || pathname === "/forgot-password";
  const isMobile = useQueryParam(constants.isMobile);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { numberOfUnreadMessages } = useSelector(messagesSelector);
  const { sessionId, userProfile } = useSelector(authSelector);
  const { globalTheme } = useSelector(globalSelector);

  const isDarkTheme = useQueryParam(constants.useDarkMode);
  const isThemeSwitchDisabled = !!isDarkTheme;

  useEffect(() => {
    dispatch(getSession());
  }, [dispatch]);

  useEffect(() => {
    if (!isMobile && sessionId) {
      const interval = setInterval(() => {
        dispatch(getSession());
        dispatch(getUnreadMessages());
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [isMobile, sessionId, dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUnreadMessages());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    const useDarkTheme = isDarkTheme === "true";
    if (useDarkTheme) {
      dispatch(setGlobalThemeAsync(constants.themeDark));
    } else {
      dispatch(setGlobalThemeAsync(constants.themeLight));
    }
  }, [isDarkTheme, dispatch]); // Added dependencies

  const handleThemeSwitchChange = (checked) => {
    if (checked === true) {
      dispatch(setGlobalThemeAsync(constants.themeDark)).then(() =>
          setStorage(storageKeys.theme, constants.themeDark)
      );
    } else {
      dispatch(setGlobalThemeAsync(constants.themeLight)).then(() =>
          setStorage(storageKeys.theme, constants.themeLight)
      );
    }
  };

  const InternalLinks = [
    {
      to: "/",
      label: t("header.internalLinks.newCredit"),
      exact: true,
    },
    {
      to: isAuthenticated || sessionId ? "/my-credits" : "/login",
      label: t("header.internalLinks.myCredits"),
      exact: false,
    },
  ];

  const MessagesLink = {
    label: t("header.internalLinks.messages"),
    to: "/messages",
    exact: false,
    bubble: numberOfUnreadMessages,
  };

  const LoginLink = {
    label: t("header.logIn"),
    to: "/login",
  };

  const ProfileLink = {
    label: t("header.profile"),
    to: "/profile",
    exact: false,
    bubble: userProfile?.idCardUploaded ? "" : "!",
  };

  const LogOutButton = {
    label: t("header.logOut"),
    as: "button",
    onClick: () => {
      dispatch(logout());
      navigate("/login"); // Updated to use navigate
    },
  };

  const MiddleNavDesktop = [
    ...ExternalLinksFirst,
    isAuthenticated ? LogOutButton : LoginLink,
  ].filter(Boolean);

  const MainNavDesktop = [
    InternalLinks[0],
    isAuthenticated && MessagesLink,
    InternalLinks[1],
    isAuthenticated && ProfileLink,
  ].filter(Boolean);

  const MainNavMobile = [
    ...InternalLinks,
    ...(isAuthenticated ? [ProfileLink, LogOutButton] : [LoginLink]),
  ].filter(Boolean);

  return (
      !isMobile && (
          <S.Header>
            <ResponsiveRender>
              {(breakpoint) => (
                  <>
                    {breakpoint.smUp && (
                        <S.TopBar>
                          <S.Content>
                            <Container>
                              <Nav
                                  align="right"
                                  items={ExternalLinksSecond}
                                  color="light"
                              />
                            </Container>
                          </S.Content>
                        </S.TopBar>
                    )}
                  </>
              )}
            </ResponsiveRender>
            <S.MiddleBar>
              <S.Content>
                <Container>
                  <ResponsiveRender>
                    {(breakpoint) => (
                        <>
                          {breakpoint.xsOnly && (
                              <S.MiddleContent>
                                <Nav.Toggle
                                    openIcon={InfoIcon}
                                    items={[
                                      ...ExternalLinksFirst,
                                      ...ExternalLinksSecond,
                                    ]}
                                />
                                <LogoComponent />
                                <Nav.Toggle items={MainNavMobile}>
                                  <Switch
                                      key="themeSwitch"
                                      onChange={handleThemeSwitchChange}
                                      checked={globalTheme === "dark"}
                                      disabled={isThemeSwitchDisabled}
                                  />
                                </Nav.Toggle>
                              </S.MiddleContent>
                          )}

                          {breakpoint.smUp && (
                              <S.MiddleContent>
                                <LogoComponent />
                                <Nav
                                    align="right"
                                    items={MiddleNavDesktop}
                                    size="large"
                                    weight={700}
                                />
                              </S.MiddleContent>
                          )}
                        </>
                    )}
                  </ResponsiveRender>
                </Container>
              </S.Content>
            </S.MiddleBar>
            <ResponsiveRender>
              {(breakpoint) => (
                  <S.MiddleBarWrapper>
                    {breakpoint.smUp && !hideMainNavDesktop && (
                        <>
                          <Nav.Main items={MainNavDesktop} />
                          <Switch
                              key="themeSwitch"
                              onChange={handleThemeSwitchChange}
                              checked={globalTheme === "dark"}
                              disabled={isThemeSwitchDisabled}
                          />
                        </>
                    )}
                  </S.MiddleBarWrapper>
              )}
            </ResponsiveRender>
          </S.Header>
      )
  );
};

export default HeaderComponent;