import React from "react";
import ReactDOM from "react-dom/client";
import { toast } from "react-toastify";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Provider as MobxProvider } from "mobx-react";

// FingerprintJS
import FingerprintJS from '@fingerprintjs/fingerprintjs';

// Analytics
import { analyticsEvents, logAnalyticsEvent, setFingerprint } from './utils/analytics';

// Utilities | Constants
import { storageKeys } from "@/constants";
import { getStorage } from "@/utils";

// Translations
import { bg } from "./i18n";

// Redux | MobX
import { Provider } from "react-redux";
import reduxStore from "./redux/store";
import rootStore from "@/stores";

// Root component
import App from "./App";

// Initialize FingerprintJS
const fpPromise = FingerprintJS.load({
  monitoring: false,
});

export const store = rootStore.create({
  authenticationState: {
    token: getStorage(storageKeys.token, undefined),
    session: {},
  },
  registrationState: {
    mobilePhone: "",
  },
  profileState: {
    profile: {},
    isPhoneVerified: false,
  },
  creditState: {
    creditProducts: [],
    creditTerms: {},
    myCredits: {
      activeCredits: [],
      avalCredits: [],
    },
  },
  referenceDataState: {
    incomeSourcesList: [],
    citiesList: [],
    creditDiscretionList: [],
    creditPurposesList: [],
  },
  creditRequestState: {
    approvalStatus: undefined,
    avalCodeVerified: false,
    avalSigned: false,
    removeRequestModalIsOpen: false,
  },
  forgotPasswordState: {
    email: undefined,
    screen: "reset-password",
  },
  addressState: {
    addressesList: [],
    coordinates: undefined,
  },
  messagesState: {
    messages: [],
    currentPage: 0,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
    unreadMessages: 0,
  },
});

i18n.use(LanguageDetector);
i18n.use(initReactI18next);
i18n.on("languageChanged", (lang) => {
  const closestLanguage = Object.keys(i18n.services.resourceStore.data).find((item) =>
      lang.match(new RegExp(item, "gi"))
  );
  const fallBack = i18n.options.fallbackLng[0];
  store.setLanguage(closestLanguage || fallBack);
});
i18n.init({
  resources: { bg: { translation: { ...bg } } },
  fallbackLng: "bg",
}).then();

// TODO: Ideally, add the 'i18n' instance to a discrete MobX store for access.
window.translate = i18n;

toast.configure({ autoClose: 14000 });

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
    <Provider store={reduxStore}>
      <MobxProvider store={store}>
        <App />
      </MobxProvider>
    </Provider>
);

// Perform side effects after rendering
(async () => {
  await Promise.all([
    (async () => {
      const fp = await fpPromise;
      const res = await fp.get();
      setFingerprint(res);
      logAnalyticsEvent(analyticsEvents.events.appOpen);
    })(),
    new Promise((resolve) =>
        setTimeout(() => {
          document.querySelector(".loader").classList.add("loaded");
          resolve();
        }, 1000)
    ),
  ]);
})();
