// Initialize Elastic APM
import { init as initApm } from '@elastic/apm-rum';

const _apm = initApm({
  serviceName: 'cashbox-web',
  serverUrl: 'https://apm.cashbox.bg',
  serviceVersion: `${process.env.REACT_APP_VERSION}`,
  environment: 'development',
  active: true,
});

import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ApmRoutes } from '@elastic/apm-rum-react';

import { theme } from '@/GlobalStyle';
import { constants, steps, storageKeys } from '@/constants';
import {
  Login,
  Register,
  ForgotPassword,
  ConfirmPhone,
  Profile,
  MyCredits,
  NotFound,
  Home,
  Messages,
} from '@/containers';
import {
  PrivateAvalsSign,
  PrivateAvalsSigned,
} from '@/containers/CreditRequest';
import { EarlyRepayment } from '@/containers/CreditRenegotiation';
import { PrivateRoute, CreditRequestModal, HeaderComponent } from '@/components';

import { useQueryParam } from './hooks';
import RenderGlobal from './RenderGlobal';
import { globalSelector, setGlobalTheme } from './redux/slices/globalSlice';
import { getStorage } from './utils';

const AppElement = styled.div`
  position: relative;

  .animated-router {
    & > * {
      width: 100%;
      position: absolute;
      background: ${({ theme }) => theme.colors.mainBackground};
    }
  }
`;

const App = () => {
  const { globalTheme } = useSelector(globalSelector);
  const isMobile = useQueryParam(constants.isMobile);
  const dispatch = useDispatch();

  const localStorageTheme = getStorage(storageKeys.theme);

  useEffect(() => {
    if (!isMobile && localStorageTheme) {
      dispatch(setGlobalTheme(localStorageTheme));
    }
  }, [isMobile, localStorageTheme, dispatch]);

  return (
      <ThemeProvider theme={theme(globalTheme)}>
        <AppElement>
          <Router>
            <HeaderComponent />
            <ApmRoutes>
              {/* Public Routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              {/* Protected Routes */}
              <Route element={<PrivateRoute redirect="/login" />}>
                <Route path="/confirm-phone" element={<ConfirmPhone />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/messages" element={<Messages />} />
                <Route path="/my-credits" element={<MyCredits />} />
                <Route
                    path="/credit-request/:requestID/private-avals-sign"
                    element={<PrivateAvalsSign />}
                />
                <Route
                    path="/credit-request/:requestID/private-avals-signed"
                    element={<PrivateAvalsSigned />}
                />
                <Route
                    path="/credit-request/:requestID/early-repayment"
                    element={<EarlyRepayment />}
                />
                {/* Dynamic Routes from steps */}
                {Object.keys(steps).map((key, index) => {
                  const {
                    slug,
                    stepWithRequestID,
                    component: StepComponent,
                    onLoad,
                  } = steps[key];
                  const path = `/credit-request${
                      stepWithRequestID ? '/:requestID' : ''
                  }${slug ? `/${slug}` : ''}`;
                  const activeStep = index + 1;

                  return (
                      <Route
                          key={key}
                          path={path}
                          element={
                            <StepComponent
                                activeStep={activeStep}
                                onLoad={onLoad}
                            />
                          }
                      />
                  );
                })}
              </Route>
              {/* Fallback Route */}
              <Route path="*" element={<NotFound />} />
            </ApmRoutes>
            <CreditRequestModal />
          </Router>
          <RenderGlobal />
        </AppElement>
      </ThemeProvider>
  );
};

export default App;