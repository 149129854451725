import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getStorage } from "@/utils";
import { storageKeys } from "@/constants";
import { getSession } from "@/redux/slices/authSlice";

const PrivateRoute = ({ redirect = "/", activeStep }) => {
    const [checkingSession, setCheckingSession] = useState(true);
    const dispatch = useDispatch();
    const isAuthenticated = getStorage(storageKeys.token);

    useEffect(() => {
        if (checkingSession) {
            dispatch(getSession()).finally(() => {
                setCheckingSession(false);
            });
        }
    }, [checkingSession, dispatch]);

    if (checkingSession) {
        // Optionally render a loading indicator
        return null;
    }

    if (!isAuthenticated) {
        return <Navigate to={redirect} replace />;
    }

    // Pass activeStep via context to child components
    return <Outlet context={{ activeStep }} />;
};

PrivateRoute.propTypes = {
    redirect: PropTypes.string,
    activeStep: PropTypes.number,
};

PrivateRoute.defaultProps = {
    redirect: "/",
};

export default PrivateRoute;
