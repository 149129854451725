import {useLocation, useNavigate} from "react-router-dom";

export default () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (baseUrl, historyState = {}) => {
    const searchURI = search || "";

    const redirectUrl = baseUrl ? `${baseUrl}${searchURI}` : "/";

    navigate(redirectUrl, {state: historyState});
  };
};