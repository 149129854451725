import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { useForm, Controller } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useBeforeUnload } from "react-use";
import { Row, Col } from "react-grid-system";
import { isEmpty } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { withSteps } from "@/hoc";
import { enums, storageKeys } from "@/constants";
import {
  CreditTotal,
  FieldGroup,
  PoliticalPersonDeclaration,
} from "@/components";
import { Form, Check, Input } from "@/components/common";
import { useFocusOnError } from "@/hooks";
import SelectControl from "./SelectControl";

import {
  creditRequestSelector,
  postInitialRequest,
  setCreditTerms,
  fetchCreditDataById,
} from "@/redux/slices/creditRequestSlice";
import { getStorage } from "@/utils";

const InitialRequestStep = ({ referenceDataState, isLoading, buttonText }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { requestID } = useParams();
  const location = useLocation();

  const { getCities } = referenceDataState;
  const { creditTerms, initialRequest } = useSelector(creditRequestSelector);
  const localStorageCreditTerms = getStorage(storageKeys.creditTerms);

  const [isFatcaObligated, setIsFatcaObligated] = useState(false);
  const [isPoliticalPerson, setIsPoliticalPerson] = useState(false);
  const [isShownCurrentAddress, setIsShownCurrentAddress] = useState(true);
  const [isShownMailingAddress, setIsShownMailingAddress] = useState(true);
  const [isFallIntoCategory_36_2, setIsFallIntoCategory_36_2] = useState(false);
  const [isFallIntoCategory_36_5, setIsFallIntoCategory_36_5] = useState(false);
  const [isNameChanged, setIsNameChanged] = useState(false);
  const [isActOnMyOwn, setIsActOnMyOwn] = useState(undefined);
  const [hasOtherBusinessRelation, setHasOtherBusinessRelation] = useState(false);
  const [isLending, setIsLending] = useState(undefined);
  const [defaultAnnualAmount, setDefaultAnnualAmount] = useState(undefined);
  const [defaultValuesWereSet, setDefaultValuesWereSet] = useState(false);
  const [lendingValue, setLendingValue] = useState(undefined);
  const [isActOnMyOwnValue, setIsActOnMyOwnValue] = useState(undefined);

  const schema = yup.object().shape({
    lendingRBG: yup
        .number()
        .typeError(t("validations.enterField"))
        .required(t("validations.enterField")),
    actOnMyOwnRBG: yup
        .number()
        .typeError(t("validations.enterField"))
        .required(t("validations.enterField")),
    businessRelations: yup.object().shape({
      expectedAnnualAmount: yup
          .object()
          .typeError(t("validations.enterЕxpectedAnnualAmount"))
          .shape({
            value: yup.string().required(t("validations.enterЕxpectedAnnualAmount")),
          }),
      businessRelation: !isLending
          ? yup.string().required(t("validations.enterField"))
          : yup.string(),
      thirdPartyName: !isActOnMyOwn
          ? yup.string().required(t("validations.enterThirdPartyName"))
          : yup.string(),
      thirdPartyEgn: !isActOnMyOwn
          ? yup.string().required(t("validations.enterThirdPartyEgn"))
          : yup.string(),
    }),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    fullWidth,
    getValues,
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fatcaObligated: false,
      businessRelations: {
        actOnMyOwn: undefined,
        crowdFunding: false,
        peerToPeer: false,
        businessRelation: null,
        thirdPartyEgn: null,
        thirdPartyName: null,
        expectedAnnualAmount: null,
      },
      politicalPersonDeclaration: null,
    },
  });

  useEffect(() => {
    if (lendingValue === 1) {
      setIsLending(true);
      setHasOtherBusinessRelation(false);
    } else if (lendingValue === 2) {
      setIsLending(false);
      setHasOtherBusinessRelation(true);
    }
  }, [lendingValue]);

  useEffect(() => {
    if (isActOnMyOwnValue === 1) {
      setIsActOnMyOwn(true);
    } else if (isActOnMyOwnValue === 2) {
      setIsActOnMyOwn(false);
    }
  }, [isActOnMyOwnValue]);

  useEffect(() => {
    getCities();
  }, [getCities]);

  useEffect(() => {
    if (requestID) {
      dispatch(fetchCreditDataById(requestID));
    }

    if ((!creditTerms || isEmpty(creditTerms)) && !!localStorageCreditTerms) {
      dispatch(setCreditTerms(JSON.parse(localStorageCreditTerms)));
    }
  }, [dispatch, requestID, creditTerms, localStorageCreditTerms]);

  useFocusOnError(errors);

  useEffect(() => {
    if (isLending !== undefined) {
      setValue("lending", isLending, { shouldValidate: false });
    }
  }, [isLending, setValue]);

  useEffect(() => {
    if (!isShownCurrentAddress && isPoliticalPerson) {
      setValue("politicalPersonDeclaration.currentAddress", null);
    }
  }, [isShownCurrentAddress, isPoliticalPerson, setValue]);

  useEffect(() => {
    if (!isShownMailingAddress && isPoliticalPerson) {
      setValue("politicalPersonDeclaration.mailingAddress", null);
    }
  }, [isShownMailingAddress, isPoliticalPerson, setValue]);

  useEffect(() => {
    if (initialRequest && !defaultValuesWereSet) {
      setIsFatcaObligated(initialRequest.fatcaObligated);
      setIsPoliticalPerson(initialRequest.politicalPerson);

      setIsLending(initialRequest.businessRelations.lending);
      setIsActOnMyOwn(initialRequest.businessRelations.actOnMyOwn);
      setHasOtherBusinessRelation(
          initialRequest.businessRelations.otherBusinessRelation
      );

      if (initialRequest.businessRelations.lending) {
        setLendingValue(1);
        setValue("lendingRBG", 1);
      } else {
        setLendingValue(2);
        setValue("lendingRBG", 2);
        setValue(
            "businessRelations.businessRelation",
            initialRequest.businessRelations.businessRelation
        );
      }

      if (initialRequest.businessRelations.actOnMyOwn) {
        setIsActOnMyOwnValue(1);
        setValue("actOnMyOwnRBG", 1);
      } else {
        setIsActOnMyOwnValue(2);
        setValue("actOnMyOwnRBG", 2);
        setValue(
            "businessRelations.thirdPartyName",
            initialRequest.businessRelations.thirdPartyName
        );
        setValue(
            "businessRelations.thirdPartyEgn",
            initialRequest.businessRelations.thirdPartyEgn
        );
      }

      const annualAmount = enums.expectedAnnualAmount.find(
          (amount) =>
              amount.value ===
              initialRequest.businessRelations.expectedAnnualAmount
      );
      setValue("businessRelations.expectedAnnualAmount", annualAmount);
      setDefaultAnnualAmount(annualAmount);
      setDefaultValuesWereSet(true);
    } else if (!initialRequest && defaultValuesWereSet) {
      setIsLending(false);
      setHasOtherBusinessRelation(false);

      reset();
    }
  }, [
    initialRequest,
    defaultValuesWereSet,
    setValue,
    setIsFatcaObligated,
    setIsPoliticalPerson,
  ]);

  useEffect(() => {
    const {
      creditProductGroupId,
      creditProductId,
      creditProductName,
      installmentAmount,
      gracePeriodInstallmentAmount,
      numberOfPayments,
      numberOfGracePeriodPayments,
      requestedAmount,
      paymentPeriod,
      creditAmountWithAvalCompany,
      installmentWithAvalCompany,
      gracePeriodInstallmentWithAvalCompany,
      interestRate,
    } = queryString.parse(location.search) || {};

    const hasQuerySelectedCreditTerms = Boolean(
        creditProductName &&
        installmentAmount &&
        gracePeriodInstallmentAmount &&
        numberOfPayments &&
        numberOfGracePeriodPayments &&
        requestedAmount &&
        paymentPeriod &&
        creditAmountWithAvalCompany &&
        installmentWithAvalCompany &&
        gracePeriodInstallmentWithAvalCompany &&
        interestRate
    );

    if (hasQuerySelectedCreditTerms) {
      dispatch(
          setCreditTerms({
            creditProductGroupId,
            creditProductId,
            creditProductName,
            installmentAmount,
            gracePeriodInstallmentAmount,
            numberOfPayments,
            numberOfGracePeriodPayments,
            requestedAmount,
            paymentPeriod,
            creditAmountWithAvalCompany,
            installmentWithAvalCompany,
            gracePeriodInstallmentWithAvalCompany,
            interestRate,
          })
      );
    }
  }, [location.search, dispatch]);

  useBeforeUnload(isPoliticalPerson, t("messages.reloadMessage"));

  const inputProps = {
    ref: register,
    errors,
    fullWidth: true,
    autoComplete: "off",
  };

  const checkProps = {
    ref: register,
    errors,
  };

  const onSubmit = (data) => {
    const { politicalPersonDeclaration, businessRelations } = data;
    const postData = {
      ...creditTerms,
      politicalPerson: isPoliticalPerson,
      fatcaObligated: isFatcaObligated,
      politicalPersonDeclaration: isPoliticalPerson
          ? {
            ...politicalPersonDeclaration,
            fatcaObligated: isFatcaObligated,
            category_36_2_ZMIP:
                politicalPersonDeclaration.category_36_2_ZMIP ?? null,
            category_36_5_ZMIP:
                politicalPersonDeclaration.category_36_5_ZMIP ?? null,
            idCardAddress: {
              ...politicalPersonDeclaration.idCardAddress,
              latitude: 21,
              longitude: 31,
            },
            currentAddress: isShownCurrentAddress
                ? politicalPersonDeclaration.currentAddress
                : null,
            mailingAddress: isShownMailingAddress
                ? politicalPersonDeclaration.mailingAddress
                : null,
            otherInformation: politicalPersonDeclaration.otherInformation ?? null,
            previousName: politicalPersonDeclaration.previousName ?? null,
            socialSecurityNumber:
                politicalPersonDeclaration.socialSecurityNumber &&
                isFatcaObligated
                    ? politicalPersonDeclaration.socialSecurityNumber
                    : null,
          }
          : null,
      businessRelations: {
        otherBusinessRelation: hasOtherBusinessRelation,
        businessRelation: hasOtherBusinessRelation
            ? businessRelations.businessRelation
            : null,
        thirdPartyName: !isActOnMyOwn
            ? businessRelations.thirdPartyName
            : null,
        thirdPartyEgn: !isActOnMyOwn ? businessRelations.thirdPartyEgn : null,
        crowdFunding: businessRelations.crowdFunding || false,
        peerToPeer: businessRelations.peerToPeer || false,
        lending: isLending,
        actOnMyOwn: isActOnMyOwn,
        expectedAnnualAmount: businessRelations.expectedAnnualAmount.value
            ? businessRelations.expectedAnnualAmount.value
            : null,
      },
    };

    dispatch(postInitialRequest(requestID ?? "", postData));
  };

  const onError = (errors, e) => {};

  const isActOnMyOwnFalsey = !isActOnMyOwn && isActOnMyOwn !== undefined;

  return (
      <Form
          isLoading={isLoading}
          buttonText={buttonText}
          onSubmit={handleSubmit(onSubmit, onError)}
      >
        {creditTerms && <CreditTotal {...creditTerms} />}

        <Check
            checked={!isFatcaObligated}
            onChange={({ currentTarget }) =>
                setIsFatcaObligated(!currentTarget.checked)
            }
        >
          Не съм данъчно задължено лице на САЩ
        </Check>

        <Check
            checked={!isPoliticalPerson}
            onChange={({ currentTarget }) =>
                setIsPoliticalPerson(!currentTarget.checked)
            }
        >
          Не съм видна политическа личност и свързан с такава личност лице
        </Check>

        <Form.Separator
            disableMarginBottom
            title="ДЕЛОВИ ВЗАИМООТНОШЕНИЯ СЪС „ЗИ-МЕТРИКС” АД ПО ЗМИП"
            titleProps={{
              size: "small",
              color: "secondary",
            }}
        >
          <Row>
            <Col xs={12}>
              <FieldGroup
                  label="Цел и характер на деловите взаимоотношения/операциите, сделките със “Зи-Метрикс” АД"
                  labelFontWeight={600}
              >
                <Check
                    {...{ errors, ref: register }}
                    name="lendingRBG"
                    type="radio"
                    checked={isLending}
                    value={1}
                    onChange={({ currentTarget }) => {
                      setLendingValue(1);
                      return true;
                    }
                    }
                >
                  {t("fields.lending")}
                </Check>
                <Check
                    {...{ errors, ref: register }}
                    name="lendingRBG"
                    type="radio"
                    checked={hasOtherBusinessRelation}
                    value={2}
                    onChange={({ currentTarget }) => {
                      setLendingValue(2);
                      return true;
                    }
                    }
                >
                  {t("fields.other")}
                </Check>
              </FieldGroup>
            </Col>

            <Col md={6}>
              <Form.ConditionalFields isShown={hasOtherBusinessRelation}>
                <Input
                    {...inputProps}
                    label={t("fields.otherBusinessRelation")}
                    name="businessRelations.businessRelation"
                />
              </Form.ConditionalFields>
            </Col>

            <Col xs={12}>
              <FieldGroup
                  label="Декларирам, че при встъпване в делови взаимоотношения със “Зи-Метрикс” АД и при извършване на опeрации или сделки в рамките на тези взаимоотношения, действам:"
                  labelFontWeight={600}
              >
                <Check
                    {...{ errors, ref: register }}
                    name="actOnMyOwnRBG"
                    type="radio"
                    checked={isActOnMyOwn}
                    value={1}
                    onChange={({ currentTarget }) => {
                      setIsActOnMyOwnValue(1);
                      return true;
                    }
                    }
                >
                  {t("fields.actOnMyOwn")}
                </Check>
                <Check
                    {...{ errors, ref: register }}
                    name="actOnMyOwnRBG"
                    type="radio"
                    checked={isActOnMyOwnFalsey}
                    value={2}
                    onChange={({ currentTarget }) => {
                      setIsActOnMyOwnValue(2);
                      return true;
                    }
                    }
                >
                  {t("fields.actOnThirdParty")}
                </Check>
              </FieldGroup>
            </Col>

            <Col md={6}>
              <Form.ConditionalFields isShown={isActOnMyOwnFalsey}>
                <Input
                    {...inputProps}
                    label={t("fields.thirdPartyName")}
                    name="businessRelations.thirdPartyName"
                />
              </Form.ConditionalFields>
            </Col>

            <Col md={6}>
              <Form.ConditionalFields isShown={isActOnMyOwnFalsey}>
                <Input
                    {...inputProps}
                    label={t("fields.thirdPartyEgn")}
                    name="businessRelations.thirdPartyEgn"
                />
              </Form.ConditionalFields>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Controller
                  as={SelectControl}
                  label={t("fields.expectedAnnualAmount")}
                  options={enums.expectedAnnualAmount}
                  name={`businessRelations.expectedAnnualAmount`}
                  errors={errors} fullWidth={fullWidth}
                  control={control}
                  defaultValue={defaultAnnualAmount}
              />
            </Col>
          </Row>
        </Form.Separator>

        <Form.ConditionalFields isShown={isPoliticalPerson}>
          <PoliticalPersonDeclaration
              checkProps={checkProps}
              control={control}
              errors={errors}
              getValues={getValues}
              inputProps={inputProps}
              isFallIntoCategory_36_2={isFallIntoCategory_36_2}
              isFallIntoCategory_36_5={isFallIntoCategory_36_5}
              isNameChanged={isNameChanged}
              isShownCurrentAddress={isShownCurrentAddress}
              isShownMailingAddress={isShownMailingAddress}
              register={register}
              setIsFallIntoCategory_36_2={setIsFallIntoCategory_36_2}
              setIsFallIntoCategory_36_5={setIsFallIntoCategory_36_5}
              setIsNameChanged={setIsNameChanged}
              setIsShownCurrentAddress={setIsShownCurrentAddress}
              setIsShownMailingAddress={setIsShownMailingAddress}
              setValue={setValue}
              t={t}
          />
        </Form.ConditionalFields>
      </Form>
  );
};

InitialRequestStep.propTypes = {
  referenceDataState: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
  backText: PropTypes.string,
};

export default compose(
    inject(({ store: { referenceDataState, isLoading } }) => ({
      referenceDataState,
      isLoading,
    })),
    observer
)(withSteps(InitialRequestStep));