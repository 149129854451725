import { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { getStorage } from "@/utils";
import { storageKeys } from "@/constants";

const AuthProvider = ({ isLoading, children, redirect, historyState }) => {
  const navigate = useNavigate();
  const isAuthenticated = getStorage(storageKeys.token);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        navigate(redirect, { state: historyState });
      }
    }
  }, [isLoading, isAuthenticated, navigate, redirect, historyState]);

  return children;
};

AuthProvider.defaultProps = { redirect: "/home" };

AuthProvider.propTypes = {
  isLoading: PropTypes.bool,
  historyState: PropTypes.object,
  redirect: PropTypes.string,
  children: PropTypes.any,
};

export default compose(
    inject(({ store: { isLoading } }) => ({
      isLoading,
    })),
    observer
)(AuthProvider);