import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { useLocation } from 'react-router-dom'; // Added useLocation
import { Button, Form } from '@/components/common';
import { PhoneCodeInput } from '@/components';
import { DefaultPage } from '@/layouts';
import { ConfirmPhoneValidator } from '@/formValidators';
import { RegisterIcon } from '@/assets/svg';
import { useFocusOnError, useRedirect } from '@/hooks';

const ConfirmPhone = ({
                        authenticationState,
                        profileState,
                        isLoading,
                      }) => {
  const { t } = useTranslation();
  const location = useLocation(); // Use the useLocation hook
  const redirect = useRedirect();
  const {
    register,
    handleSubmit,
    errors,
  } = useForm({ validationSchema: ConfirmPhoneValidator() });

  const sessionMobilePhone =
      authenticationState.session?.userProfile?.mobilePhone;
  const stateMobilePhone = location?.state?.mobilePhone;
  const mobilePhone = stateMobilePhone || sessionMobilePhone;

  useFocusOnError(errors);

  useEffect(() => {
    authenticationState.getSession();
  }, [authenticationState]);

  useEffect(() => {
    if (!isLoading) {
      if (profileState.isPhoneVerified) {
        redirect(location?.state?.redirectAfterConfirm);
      }
    }
  }, [
    isLoading,
    profileState.isPhoneVerified,
    redirect,
    location?.state?.redirectAfterConfirm,
  ]);

  return (
      <DefaultPage
          head={{
            title: t('global.profile'),
            subTitle: 'Потвърждаване на телефонния номер',
            icon: RegisterIcon,
          }}
      >
        <Form
            isLoading={isLoading}
            onSubmit={handleSubmit((data) => profileState.validatePhone(data))}
            buttonText="Потвърди и продължи"
            hasBackground
        >
          <PhoneCodeInput
              mobilePhone={mobilePhone}
              ref={register}
              errors={errors}
              name="code"
          />
          <Button.Text onClick={() => profileState.resendPhoneValidation()}>
            Изпрати нов код
          </Button.Text>
        </Form>
      </DefaultPage>
  );
};

ConfirmPhone.propTypes = {
  authenticationState: PropTypes.object.isRequired,
  profileState: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default compose(
    inject(({ store: { authenticationState, profileState, isLoading } }) => ({
      authenticationState,
      profileState,
      isLoading,
    })),
    observer
)(ConfirmPhone);